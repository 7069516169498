import jQuery from 'jquery';
import LazyLoad from 'vanilla-lazyload';
import 'leaflet';

require('bootstrap');
window.$ = jQuery;
window.jQuery = jQuery;

(($) => {
    let tabTitle = $(".title");

    let elements = "";
    Array.prototype.forEach.call(tabTitle, element => {
        let simplElement = element.innerHTML;
        let elementChanged = "#" + element.innerHTML.split(" ").join("_");

        let redirect = '<a href="' + elementChanged + '"><p class="title-menu "> ' + simplElement + '</p></a>'
        $(".anchor").append(redirect);
    });


    if ($('#map').length) {
        let myLatLng = {lat: 49.4792311, lng: 6.0854687};

        var map = L.map('map');

        L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png',).addTo(map);

        // LIGNE 18
        map.setView([49.4792311, 6.0854687], 14);

        var marker = L.marker([49.4792311, 6.0854687]).addTo(map);
        marker.bindPopup("Cronos Investments").openPopup();

    }
    if ($('#map2').length) {
        let myLatLng = {lat: 49.4792311, lng: 6.0854687};

        var map2 = L.map('map2');

        L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png',).addTo(map2);

        // LIGNE 18
        map.setView([49.4792311, 6.0854687], 14);

        var marker2 = L.marker([49.4792311, 6.0854687]).addTo(map2);
        marker2.bindPopup("Cronos Investments").openPopup();

    }


    $("#senderMail").on("click", () => {
        let name = $("#name").val();
        let subject = $("#subject").val();
        let body = $("#body").val();

        window.open('mailto:contact@cronos-investments.com?subject=' + name +' - '+subject+ '&body=' + body);
    });

    $('.hamburger-box').on('click', () => {
        $('.main-menu').addClass('isOpened');
    });
    $('.anchor a').on('click', () => {
        $('.main-menu').removeClass('isOpened');

    });

    new LazyLoad({
        // Your custom settings go here
    });


    $('.anchor a').on('click', (function () {
        if (window.innerWidth < 992) {

            if ($(window).scrollTop() > 0) {
                $('html, body').animate({
                    scrollTop: $(this.hash).offset().top-90
                }, 10);
            } else {
                $('html, body').animate({
                    scrollTop: $(this.hash).offset().top-90
                }, 10);
            }
        } else {

            $('html, body').animate({
                scrollTop: $(this.hash).offset().top
            }, 10);
        }
    }));
})(jQuery);
